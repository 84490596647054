<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Editar Aplicação">
      <v-skeleton-loader
          v-if="loading"
          class="pa-5"
          type='list-item-two-line, card, sentences, table-tfoot'
        ></v-skeleton-loader>
      <form-exam-application
        v-else
        action="edit"
        :applicationData="form"
      ></form-exam-application>
    </base-card>
  </v-container>
</template>
<script>
import { parseISO } from 'date-fns'
import collect from 'collect.js'

export default {
  data: () => ({
    page: {
      title: 'Editar Aplicação'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Avaliações',
        disabled: false,
        to: '/exam/list'
      },
      {
        text: 'Editar Aplicação',
        disabled: false,
        to: '/exam/application'
      }
    ],
    form: {},
    loading: false
  }),
  methods: {
    formatArray (array, type = null) {
      return collect(array).map(item => {
        return {
          name: type === 'schoolGrade' ? `${item.name} | ${item.name_abbr}` : item.name,
          value: item.id
        }
      }).all()
    },
    getApplication () {
      this.loading = true
      const { id, application } = this.$route.params
      const organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
      this.$axios.get(`organizations/${organizationId}/exams/${id}/applications/${application}`)
        .then(response => response.data)
        .then(application => {
          this.form = {
            ...application.data,
            ends_at: parseISO(application.data.ends_at),
            starts_at: parseISO(application.data.starts_at),
            courses_group: this.formatArray(application.data.courses_group),
            school_grades_group: this.formatArray(application.data.school_grades_group, 'schoolGrade'),
            schools_group: this.formatArray(application.data.schools_group),
            students_group: this.formatArray(application.data.students_group)
          }
        })
        .catch(() => {
          // TODO: Refact this
          this.$store.dispatch('alert', { color: 'red', msg: 'Erro na conexão. Tente novamente' })
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created () {
    this.getApplication()
  }
}
</script>
